import React from 'react'
import Helmet from 'react-helmet'
import { GlobalStyles } from './src/styles/global'
import { ThemeProvider } from './src/context/ThemeContext'
import { IsMobileProvider } from './src/context/IsMobileContext'

export default ({ element, props  }) => {
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="https://use.typekit.net/xou8xhg.css" />
            </Helmet>
            <GlobalStyles/>
            <IsMobileProvider>
                <ThemeProvider>
                    {element}
                </ThemeProvider>
            </IsMobileProvider>
        </>
    )
}