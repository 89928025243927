import React, { useState, useEffect } from 'react'
export const IsMobileContext = React.createContext()
import { useMount } from 'react-use'
import { deviceDetect } from 'react-device-detect'
import { useBreakpoint } from '../styles/utils'

const IsMobileProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false)
    const isPhone = useBreakpoint('phone')

    useMount(() => {
        // ssr fix
        if (deviceDetect().isMobile) {
            setIsMobile(true)
        }
    })

    useEffect(() => {
        if (isMobile !== isPhone) {
            if (!isPhone) {
                setIsMobile(false)
            } else {
                setIsMobile(true)
            }
        }
    }, [isPhone])

    return (
        <IsMobileContext.Provider
            value={{
                isMobile: isMobile
            }}
        >
            {children}
        </IsMobileContext.Provider>
    )
}

export default IsMobileContext

export { IsMobileProvider }