import { css } from 'styled-components'
import { media } from '../styles/utils'

const type = {
    headerLinks: css`
        font-family: "sofia-pro";
		font-size: 18px;
		line-height: 1;

        ${media.phone`
            font-size: 14px;  
        `}
    `,
    heading1: css`
        font-family: Morion;
        font-size: 56px;
        line-height: 1.2;

        ${media.phone`
            font-size: 33px;
        `}
    `,
    heading2: css`
        font-family: Morion;
        font-size: 32px;
        line-height: 1.2;

        ${media.phone`
            font-size: 20px;
        `}
    `,
    body: css`
        font-family: "sofia-pro";
        font-size: 22px;
        line-height: 1.2;

        ${media.phone`
            font-size: 14px;
        `}
    `,
    footerText: css`
        font-family: "sofia-pro";
        font-size: 22px;
        line-height: 1.2;

        ${media.phone`
            font-size: 14px;
        `}
    `
}

export default type