import React, { useState, useEffect, useContext } from 'react'
import { createGlobalState, useCounter } from 'react-use'
export const ThemeContext = React.createContext()
import { partners } from '../data/partners'

const ThemeProvider = ({ children }) => {
    const min = 1
    const max = partners.length
    const [activeIndex, { inc, dec, get, set, reset }] = useCounter(min, max, min)
    const [activeIndexOverride, setActiveIndexOverride] = useState(false)
    const [colour, setColour] = useState(partners[activeIndex-1].colour)
    const duration = 3

    useEffect(() => {
        const timer = setInterval(() => {
            if (get() < max) {
                inc()
            } else {
                reset()
            }
        }, 5000)
        return () => clearInterval(timer)
    }, [])

    useEffect(() => {
        if (!activeIndexOverride) {
            setColour(partners[activeIndex-1].colour)
        }
    }, [activeIndex])

    useEffect(() => {
        if (activeIndexOverride && partners[activeIndexOverride-1].colour !== colour) {
            setColour(partners[activeIndexOverride-1].colour)
        }
        if (!activeIndexOverride) {
            setColour(partners[activeIndex-1].colour)
        }
    }, [activeIndexOverride])

    return (
        <ThemeContext.Provider
            value={{
                activeIndex: activeIndex,
                activeIndexOverride: activeIndexOverride,
                setActiveIndexOverride: setActiveIndexOverride,
                colour: colour,
                duration: duration,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContext

export { ThemeProvider }
