import { stripIndent } from "common-tags";
export const partners = [
    {
        name: 'Brickfields',
        colour: '#4fb5d1',
        logo: require('../assets/logos/brickfields-logo.svg'),
        mobileLogoWidth: '253px', 
        image: require('../assets/images/partners/brickfields.jpg'),
        text: stripIndent`
            <p>
                Specialist property research<br>
                and strategy
            </p>
        `,
        website: 'https://brickfields.com'
    },
    {
        name: 'Engage',
        colour: '#00c4b2',
        logo: require('../assets/logos/engage-logo.svg'),
        mobileLogoWidth: '136px',
        image: require('../assets/images/partners/engage.jpg'),
        text: stripIndent`
            <p>
                Building relationships between<br>
                brands and audiences
            </p>
        `,
        website: 'https://www.engageatdisegno.com.au'
    },
    {
        name: 'Hundred Weight',
        colour: '#00ff9d',
        logo: require('../assets/logos/hundredweight-logo.svg'),
        mobileLogoWidth: '226px',
        image: require('../assets/images/partners/hundredweight.jpg'),
        text: stripIndent`
            <p>
                Branding & design to inspire people<br>
                and transform places
            </p>
        `,
        website: 'https://hundredweight.com.au'
    },
    {
        name: 'Conversation Caravan',
        colour: '#9dcfd0',
        logo: require('../assets/logos/conversation-caravan-logo.svg'),
        mobileLogoWidth: '172px',
        image: require('../assets/images/partners/conversation.jpg'),
        text: stripIndent`
            <p>
                Bringing decision makers and<br>
                community together
            </p>
        `,
        website: 'https://caravan.conversationco.com.au'
    }
]